import {Container} from 'react-bootstrap';
import Header from "./components/Header";
import Footer from "./components/Footer";
import FileInput from './components/FileInput';

function App() {
  return (
    <div>
      <Header />
      <main className='py-3'>
        <Container>
          <FileInput />
        </Container>
      </main>
      {/* <Footer /> */}
    </div>
  );
}

export default App;
