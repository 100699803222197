import React, { useState, useEffect } from "react";
import Spinner from "react-bootstrap/Spinner";
import axios from "axios";
import Table from "react-bootstrap/Table";
import nb from "../nb_model.xlsx";
import * as XLSX from "xlsx";
import InnerHTML from "dangerously-set-html-content";
import { Form } from "react-bootstrap";
import RangeSlider from 'react-bootstrap-range-slider';

function FileInput() {
  const [isSelected, setIsSelected] = useState(false);
  const [selectedFile, setSelectedFile] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [updatedFile, setUpdatedFile] = useState();
  const [isUpdated, setIsUpdated] = useState(false);
  const [metabolites, setMetabolites] = useState([]);
  const [reactions, setReactions] = useState([]);
  const [fba, setFba] = useState();
  const [lb, setLb] = useState([]);


  const changeHandler = async (event) => {
    event.preventDefault();
    setSelectedFile(event.target.files[0]);
    excelHandler(event);
  };

  const checkboxHandler = async (event) => {
    // event.preventDefault();
    if(event.target.checked){
      console.log(event.target.value)
      setFba(event.target.value)
    }
  };

  const lbHandler = async(event) => {
    event.preventDefault();
    console.log(event.target.name);
    console.log(event.target.value);
    const updatedLb = reactions.map(item => {
      if(event.target.name === item.ID){
        return {...item, LB: event.target.value};
      }else{
        return item;
      }
    })
    setReactions(updatedLb);
    console.log(updatedLb);
  }

  const ubHandler = async(event) => {
    event.preventDefault();
    console.log(event.target.name);
    console.log(event.target.value);
    const updatedUb = reactions.map(item => {
      if(event.target.name === item.ID){
        return {...item, UB: event.target.value};
      }else{
        return item;
      }
    })
    setReactions(updatedUb);
    console.log(updatedUb);
  }


  const excelHandler = async (event) => {
    setIsSelected(true);
    const user_file = await event.target.files[0].arrayBuffer();
    const excel_file = XLSX.read(user_file);
    let metabolite_sheet = excel_file.Sheets[excel_file.SheetNames[0]];
    // console.log(metabolite_sheet)
    let reaction_sheet = excel_file.Sheets[excel_file.SheetNames[1]];
    // console.log(reaction_sheet)
    let metabolite_json = XLSX.utils.sheet_to_json(metabolite_sheet);
    // console.log(metabolite_json);
    setMetabolites(metabolite_json);
    let reaction_json = XLSX.utils.sheet_to_json(reaction_sheet);
    // console.log(reaction_json);
    setReactions(reaction_json);

  };

  const handleSubmission = async (event) => {
    event.preventDefault();
    console.log(window.innerWidth+" * "+window.innerHeight);
    console.log(isLoading);
    console.log(selectedFile);
    setIsLoading(true);
    const formData = new FormData();
    formData.append("File", selectedFile);
    if(fba == null){
      formData.append("Value", "None");
    }else{
      formData.append("Value", fba);
    }


    try {
      const response = await axios({
        headers: {
          "Content-Type": "multipart/form-data",
        },
        url: "/api/userinput/",
        method: "POST",
        data: formData,
      });
      if (response) {
        console.log("Received Response From Backend");
        setUpdatedFile(response.data);
        setIsUpdated(true);
        setIsLoading(false);
        setIsSelected(false);
      }
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <div className="col text-center">
      {isUpdated ? (
        // <div dangerouslySetInnerHTML={{ __html: updatedFile }}></div>
            <InnerHTML html={updatedFile} />
      ) : (
        <form onSubmit={handleSubmission}>
          <fieldset>
            <div className="form-group">
              <label htmlFor="formFile" className="form-label mt-4">
                Please Upload Your Excel File:
              </label>
              <input
                className="form-control"
                type="file"
                id="formFile"
                onChange={changeHandler}
                encType="multipart/form-data"
                required
              ></input>
            </div>
          </fieldset>
          <br />
          {isLoading ? (
            <Spinner animation="grow" />
          ) : (
            <>
              {!isSelected ? (
                console.log("Not Selected")
              ) : (
                <Table striped bordered hover>
                  <thead>
                    <tr>
                      <th>Maximize</th>
                      <th>Reaction ID</th>
                      <th>Reaction Name</th>
                      <th>Lower Bound</th>
                      <th>Upper Bound</th>
                    </tr>
                  </thead>
                  <tbody>
                    {reactions.map((reaction) => (
                      <tr key={reaction.ID}>
                        <td>
                          {/* <Form.Check /> */}
                          <input
                            type="checkbox"
                            name="fba"
                            onChange={checkboxHandler}
                            // onSelect={changeHandler}
                            value={reaction.ID}
                          />
                        </td>
                        <td>{reaction.ID}</td>
                        <td>{reaction.Name}</td>
                        <td>{reaction.LB}</td>
                        {/* <td><RangeSlider
                        name = {reaction.ID}
                        min={-100}
                        max={100}
                        value={reaction.LB}
                        onChange={lbHandler}
                        step = {0.5}
                        /></td> */}
                        <td>{reaction.UB}</td>
                        {/*<td><RangeSlider
                        name = {reaction.ID}
                        min={-100}
                        max={100}
                        value={reaction.UB}
                        onChange={ubHandler}
                        step = {0.5}
                        /></td>*/}
                      </tr>
                    ))}
                  </tbody>
                </Table>
              )}
              <button
                type="submit"
                className="btn btn-primary"
                disabled={isLoading}
              >
                Submit
              </button>
            </>
          )}
        </form>
      )}
    </div>
  );
}

export default FileInput;
